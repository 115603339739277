import PerformanceItem from '@/models/PerformanceItem';

export const formatNumber = (
    amount: number | string,
    decimalCount = 2,
    decimal = '.',
    thousands = ',',
    prefix = '£'
): string => {
    let absDecimalCount = Math.abs(decimalCount);
    absDecimalCount = Number.isNaN(absDecimalCount) ? 2 : absDecimalCount;

    let i = null;
    let negativeSign = null;
    if (typeof amount === 'number') {
        negativeSign = Number(amount) < 0 ? '-' : '';
        i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(absDecimalCount))).toString();

    } else {
        // correctly parse thousands strings, eg 10,000.00
        negativeSign = ((Number(amount.toString().replaceAll(/[,]/g, ''))) || 0) < 0 ? '-' : '';
        i = parseInt((amount = Math.abs(Number(amount.toString().replaceAll(/[,]/g, '')) || 0).toFixed(absDecimalCount))).toString();
    }

    const j = i.length > 3 ? i.length % 3 : 0;
    return (
        negativeSign +
        prefix +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
        (decimalCount
            ? decimal +
            Math.abs(parseFloat(amount) - parseFloat(i))
                .toFixed(decimalCount)
                .slice(2)
            : '')
    );
};

export const getStatusClass = (
    item: PerformanceItem<string | number>
): string => {
    let cssClass = '';
    if (item.rowID && item.meta.edited) {
        cssClass = 'editedValue';
    }
    if (item.rowID && item.isDelete) {
        cssClass = 'toBeDeleted';
    }
    if (item.rowID === null && item.meta.edited) {
        cssClass = 'newValue';
    }
    if (item.rowID === null && item.meta.edited && item.tickerValue.toString() === '') {
        cssClass = '';
    }

    return cssClass;
};